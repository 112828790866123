import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../styles/404.sass'

const CtfPage = () => (
    <Layout>
        <SEO title="CTF" />
        <center>
            <h1 style={{ fontSize: '800%' }}>CTF IN THE MAKING...</h1>
            <p style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
                Revenez plus tard!
                <br />
                <br />
                <br />
                <br />
            </p>
        </center>
    </Layout>
)

export default CtfPage
